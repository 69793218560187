/* eslint-disable @typescript-eslint/no-explicit-any */
import { isNativeApp } from '../../../client'

export const mediaCdn = 'https://media3.reshet.tv/image/upload/'

const liveEntriesRefs = {
  noCaptions: {
    e000: '1_ubg28z0w', //Israel_No_Subtitles_Web
    e010: '1_n3fizy37', //Global_No_Subtitles_Web
    e100: '1_x3xriuot', //Israel_No_Subtitles_Mobile
    e110: '1_l38msgjy', //Global_No_Subtitles_Mobile
  },
  captions: {
    e001: '1_8dlti2jz', //Israel_Subtitles_Web
    e011: '1_zb13i6jt', //Global_Subtitles_Web
    e101: '1_bkg2huh9', //Israel_Subtitles_Mobile
    e111: '1_elncycc8', //Global_Subtitles_Mobile
  },
}

const liveBBEntries = {
  noCaptions: {
    e000: '1_6fr5xbw2', //Israel_No_Subtitles_Web
    e010: '1_6fr5xbw2', //Global_No_Subtitles_Web
    e100: '1_6fr5xbw2', //Israel_No_Subtitles_Mobile
    e110: '1_6fr5xbw2', //Global_No_Subtitles_Mobile
  },
  captions: {
    e001: '1_6fr5xbw2', //Israel_Subtitles_Web
    e011: '1_6fr5xbw2', //Global_Subtitles_Web
    e101: '1_6fr5xbw2', //Israel_Subtitles_Mobile
    e111: '1_6fr5xbw2', //Global_Subtitles_Mobile
  },
}

export const subtitlesData = {
  video_accessibility_cookie_name: 'r13_video_accessibility',
  video_accessibility_cookie_hour: 3, //3 hours
}

export const mode = {
  BB: 'BB',
  live: 'live',
}
const video_ads_prefix =
  'https://pubads.g.doubleclick.net/gampad/ads?iu=/36476105'
export const playerConfig: any = {
  liveEntries: {
    noCaptions: liveEntriesRefs.noCaptions,
    captions: liveEntriesRefs.captions,
  },

  liveBBEntries: {
    noCaptions: liveBBEntries.noCaptions,
    captions: liveBBEntries.captions,
  },
  DEFAULT_PLAYER: 1,
  INACTIVE_TIMEOUT: 1800000, // 30min
  NEW_DAY_TIMEOUT: 60 * 60 * 24 * 1000, //24h
  geo_cookie_name: 'r13_geo',
  MobileBreakPoint: 978,
  tvRed: null as any,
  video_accessibility_cookie_name: 'r13_video_accessibility',
  VOD_MAIN_PLAYER: 'VOD_MAIN_PLAYER',
  DFPParams: {
    timer_BB: 8.25, //minutes
    timer_VOD: 8.25, //minutes
    refresh_timer: 60, // minutes
    DFP_ON: true,
    ADS_VIDEO: {
      // news: {
      //   //desktop
      //   d_vod: `${video_ads_prefix}/desktop/news/vod&description_url=https%3A%2F%2F13news.co.il%2F&tfcd=0&npa=0&sz=640x480&gdfp_req=1&output=vmap&ad_rule=1&cmsid=2546096&unviewed_position_start=1&env=vp&impl=s&correlator=`,
      //   d_live_preroll: `${video_ads_prefix}/desktop/news/live/preroll&description_url=https%3A%2F%2F13news.co.il%2F&tfcd=0&npa=0&sz=640x480&gdfp_req=1&output=vmap&ad_rule=1&cmsid=2546096&unviewed_position_start=1&env=vp&impl=s&correlator=`,
      //   d_live_midroll: `${video_ads_prefix}/desktop/news/live/midroll&description_url=https%3A%2F%2F13news.co.il%2F&tfcd=0&npa=0&sz=640x480&gdfp_req=1&output=vmap&ad_rule=1&cmsid=2546096&unviewed_position_start=1&env=vp&impl=s&correlator=`,
      //
      //   //mobile
      //   m_vod: `${video_ads_prefix}/mweb/news/vod&description_url=https%3A%2F%2F13news.co.il%2F&tfcd=0&npa=0&sz=640x480&gdfp_req=1&output=vmap&ad_rule=1&cmsid=2546096&unviewed_position_start=1&env=vp&impl=s&correlator=`,
      //   m_live_preroll: `${video_ads_prefix}/mweb/news/live/preroll&description_url=13news.co.il&tfcd=0&npa=0&sz=640x480&gdfp_req=1&output=vmap&ad_rule=1&cmsid=2546096&unviewed_position_start=1&env=vp&impl=s&correlator=`,
      //   m_live_midroll: `${video_ads_prefix}/mapp/news/live/midroll&description_url=13news.co.il&tfcd=0&npa=0&sz=640x480&gdfp_req=1&output=vmap&ad_rule=1&cmsid=2546096&unviewed_position_start=1&env=vp&impl=s&correlator=`,
      //
      //   //app
      //   a_vod: `${video_ads_prefix}/mapp/news/vod&description_url=https%3A%2F%2F13news.co.il%2F&tfcd=0&npa=0&sz=640x480&gdfp_req=1&output=vmap&ad_rule=1&cmsid=2546096&unviewed_position_start=1&env=vp&impl=s&correlator=`,
      //   a_live_preroll: `${video_ads_prefix}/mapp/news/live/preroll&description_url=https%3A%2F%2F13news.co.il%2F&tfcd=0&npa=0&sz=640x480&gdfp_req=1&output=vmap&ad_rule=1&cmsid=2546096&unviewed_position_start=1&env=vp&impl=s&correlator=`,
      //   a_live_midroll: `${video_ads_prefix}/mapp/news/live/midroll&description_url=https%3A%2F%2F13news.co.il%2F&tfcd=0&npa=0&sz=640x480&gdfp_req=1&output=vmap&ad_rule=1&cmsid=2546096&unviewed_position_start=1&env=vp&impl=s&correlator=`,
      // },

      tv: {
        //desktop
        d_vod: `${video_ads_prefix}/desktop/reshet/vod&description_url=https%3A%2F%2F13tv.co.il%2F&tfcd=0&npa=0&sz=640x480&gdfp_req=1&output=vmap&ad_rule=1&cmsid=2546096&unviewed_position_start=1&env=vp&impl=s&correlator=`,
        d_live_preroll: `${video_ads_prefix}/desktop/reshet/live/preroll&description_url=13tv.co.il&tfcd=0&npa=0&sz=640x480&gdfp_req=1&output=vmap&ad_rule=1&cmsid=2546096&unviewed_position_start=1&env=vp&impl=s&correlator=`,
        d_live_midroll: `${video_ads_prefix}/desktop/reshet/live/midroll&description_url=13tv.co.il&tfcd=0&npa=0&sz=640x480&gdfp_req=1&output=vmap&ad_rule=1&cmsid=2546096&unviewed_position_start=1&env=vp&impl=s&correlator=`,
        d_bb_preroll: `${video_ads_prefix}/desktop/reshet/26/preroll&description_url=https%3A%2F%2F13tv.co.il%2F&tfcd=0&npa=0&sz=640x480&gdfp_req=1&output=vmap&ad_rule=1&cmsid=2546096&unviewed_position_start=1&env=vp&impl=s&correlator=`,
        d_bb_midroll: `${video_ads_prefix}/desktop/reshet/26/midroll&description_url=https%3A%2F%2F13tv.co.il%2F&tfcd=0&npa=0&sz=640x480&gdfp_req=1&output=vmap&ad_rule=1&cmsid=2546096&unviewed_position_start=1&env=vp&impl=s&correlator=`,

        //mobile
        m_vod: `${video_ads_prefix}/mweb/reshet/vod&description_url=https%3A%2F%2F13tv.co.il%2F&tfcd=0&npa=0&sz=640x480&gdfp_req=1&output=vmap&ad_rule=1&cmsid=2546096&unviewed_position_start=1&env=vp&impl=s&correlator=`,
        m_live_preroll: `${video_ads_prefix}/mweb/reshet/live/preroll&description_url=13tv.co.il&tfcd=0&npa=0&sz=640x480&gdfp_req=1&output=vmap&ad_rule=1&cmsid=2546096&unviewed_position_start=1&env=vp&impl=s&correlator=`,
        m_live_midroll: `${video_ads_prefix}/mweb/reshet/live/midroll&description_url=13tv.co.il&tfcd=0&npa=0&sz=640x480&gdfp_req=1&output=vmap&ad_rule=1&cmsid=2546096&unviewed_position_start=1&env=vp&impl=s&correlator=`,
        m_bb_preroll: `${video_ads_prefix}/mweb/reshet/26/preroll&description_url=https%3A%2F%2F13tv.co.il%2F&tfcd=0&npa=0&sz=640x480&gdfp_req=1&output=vmap&ad_rule=1&cmsid=2546096&unviewed_position_start=1&env=vp&impl=s&correlator=`,
        m_bb_midroll: `${video_ads_prefix}/mweb/reshet/26/midroll&description_url=https%3A%2F%2F13tv.co.il%2F&tfcd=0&npa=0&sz=640x480&gdfp_req=1&output=vmap&ad_rule=1&cmsid=2546096&unviewed_position_start=1&env=vp&impl=s&correlator=`,
        //app
        a_vod: `${video_ads_prefix}/mapp/reshet/vod&description_url=https%3A%2F%2F13tv.co.il%2F&tfcd=0&npa=0&sz=640x480&gdfp_req=1&output=vmap&ad_rule=1&cmsid=2546096&unviewed_position_start=1&env=vp&impl=s&correlator=`,
        a_live_preroll: `${video_ads_prefix}/mapp/reshet/live/preroll&description_url=13tv.co.il&tfcd=0&npa=0&sz=640x480&gdfp_req=1&output=vmap&ad_rule=1&cmsid=2546096&unviewed_position_start=1&env=vp&impl=s&correlator=`,
        a_live_midroll: `${video_ads_prefix}/mapp/reshet/live/midroll&description_url=13tv.co.il&tfcd=0&npa=0&sz=640x480&gdfp_req=1&output=vmap&ad_rule=1&cmsid=2546096&unviewed_position_start=1&env=vp&impl=s&correlator=`,
        a_bb_preroll: `${video_ads_prefix}/mapp/reshet/26/preroll&description_url=https%3A%2F%2F13tv.co.il%2F&tfcd=0&npa=0&sz=640x480&gdfp_req=1&output=vmap&ad_rule=1&cmsid=2546096&unviewed_position_start=1&env=vp&impl=s&correlator=`,
        a_bb_midroll: `${video_ads_prefix}/mapp/reshet/26/midroll&description_url=https%3A%2F%2F13tv.co.il%2F&tfcd=0&npa=0&sz=640x480&gdfp_req=1&output=vmap&ad_rule=1&cmsid=2546096&unviewed_position_start=1&env=vp&impl=s&correlator=`,
      },
      reels: {
        // Desktop
        d_reels: `${video_ads_prefix}/Vertical/Vertical_Desktop&description_url=https%3A%2F%2F13tv.co.il%2F&tfcd=0&npa=0&sz=1080x1920&gdfp_req=1&unviewed_position_start=1&output=vast&env=vp&impl=s&correlator=`,
        // Mobile
        m_reels: `${video_ads_prefix}/Vertical/Vertical_Mobile&description_url=https%3A%2F%2F13tv.co.il%2F&tfcd=0&npa=0&sz=1080x1920&gdfp_req=1&unviewed_position_start=1&output=vast&env=vp&impl=s&correlator=`,
        // App
        a_reels: `${video_ads_prefix}/Vertical/Vertical_App&description_url=https%3A%2F%2F13tv.co.il%2F&tfcd=0&npa=0&sz=1080x1920&gdfp_req=1&unviewed_position_start=1&output=vast&env=vp&impl=s&correlator=`,
      },
    },
    ASEET_KEY: {
      Israel: {
        news: {
          d_key: 'g_-iak23ToK9O1PjuEVAzQ',
          m_key: 'P3CNpUG9TeKUgWZzSS4QIA',
          a_key: 'cj1lDSglQ7iQZPE80jKPTA',
        },
        tv: {
          d_key: 'OpZqIPuwR4md7SbGptVDFQ',
          m_key: 'jnzr1hxGTseHG3-_tQjsaA',
          a_key: '9Ci746QUTPOcPwIGUIfFSQ',
        },
      },
      Global: {
        news: {
          d_key: 'w1k4xmjmRJWqM2x35Yyljw',
          m_key: 'lR15zc-aRnalGvPqTgGbvA',
          a_key: 'Q-uNb1FHSIiPeXCwhFA0zA',
        },
        tv: {
          d_key: 'fDTiATvlRXuRyf_Wy-cOkw',
          m_key: 'aBQZr-teQlSKwVOgflncQg',
          a_key: 'KV9uTCDuT7CryS5SnlDa0A',
        },
      },
      Token: 'Mv8mqltnTEGZKwDp-VJxYw',
    },
  },
  inWebView: typeof window !== 'undefined' ? isNativeApp() : false,
  fakeWindow: {
    location: { href: '' },
    siteConfig: {
      default_images: {
        subtitleBtn: mediaCdn + 'v1643885178/assets/icons/ear.png',
        subtitleBtnIcons: {
          on: mediaCdn + 'v1645538480/uploads/2022/860397.png',
          off: mediaCdn + 'v1645538478/uploads/2022/860396.png',
        },
        tv: {
          default_image:
            mediaCdn + 'v1633341900/assets/2021/default_image_tv.webp',
          ad_block: mediaCdn + 'v1669283043/assets/2023/Adblock.webp',
          unavailable: mediaCdn + 'v1633341901/assets/2021/13_Unavailable.webp',
          overseas: mediaCdn + 'v1633341901/assets/2021/13_Overseas.webp',
          logo_horizontal: mediaCdn + 'v1638175012/assets/2021/RESHET13.webp',
          logo_on_black:
            mediaCdn +
            'v1633506608/assets/2021/Logo_horizontal_for_header_on_black.webp',
        },
        news: {
          default_image:
            mediaCdn + 'v1633341900/assets/2021/default_image_news.webp',
          ad_block: mediaCdn + 'v1669283043/assets/2023/Adblock.webp',
          unavailable:
            mediaCdn + 'v1633341900/assets/2021/News_Unavailable.webp',
          overseas: mediaCdn + 'v1633341900/assets/2021/News_Overseas.webp',
          logo_horizontal:
            mediaCdn + 'v1632992987/assets/2021/logo_horizontal.webp',
          logo_on_black:
            mediaCdn +
            'v1633506608/assets/2021/Logo_horizontal_for_header_on_black.webp',
        },
      },
      liveRefId: {
        stream_reshet_live1_dvr: {
          mod: mode.live,
          ch: 1,
          kantar: 1,
          entries: liveEntriesRefs.noCaptions,
        },
        stream_reshet_live1: {
          mod: mode.live,
          ch: 1,
          kantar: 1,
          entries: liveEntriesRefs.noCaptions,
        },
        stream_reshet_live1_captions: {
          mod: mode.live,
          ch: 27,
          kantar: 1,
          entries: liveEntriesRefs.captions,
        },
        stream_reshet_live1_dvr_captions: {
          mod: mode.live,
          ch: 27,
          kantar: 1,
          entries: liveEntriesRefs.captions,
        },
        stream_reshet_bb01: {
          mod: mode.BB,
          ch: 26,
          entries: liveBBEntries.noCaptions,
        }, //big brother
        stream_reshet_bb01_captions: {
          mod: mode.BB,
          ch: 27,
          entries: liveBBEntries.captions,
        }, //big brother
        bigbrother_live_drm_2023: {
          mod: mode.BB,
          ch: 27,
          entries: liveBBEntries.captions,
        },
        bigbrother_live_2023: {
          mod: mode.BB,
          ch: 27,
          entries: liveBBEntries.captions,
        },
      } as const,
      kaltura: {
        partnerId: '2748741',
        uiConfId: '50272542',
        vastLoadTimeout: 15000,
        mobileAutoplayChrome: true,
        geoLocation: {
          defaultGeoResponse: {
            country: 'Israel',
            ip: null,
          },
          default_cookie_days: 14,
          cookie_days: 7,
        },
        video_accessibility_cookie_hour: 3, //3 hours
        cdn: {
          kalturaCdn:
            'https://cdnapisec.kaltura.com/p/2748741/embedPlaykitJs/uiconf_id/50272542',
          akamaiCdn: '//akamai.advsnx.net/CDN/sdk/plugins/kaltura/plugin.js',
          kantarCdn:
            'https://13tv.co.il/wp-content/themes/reshet_tv/build/third-party/playkit/playkit-kantar.js',
        },
        plugin: {
          kaltura: 'kaltura',
          akamai: 'akamai',
          kantar: 'kantar',
          ima: 'ima',
          imadai: 'imadai',
        },
      },
      kantar_site_name: null,
      adds: { dayViewMaxCount: 5 },
    },
    activePlayer: null as any as HTMLElement,
    refreshTimeOut: null as any as number,
    analiticsInterval: null as any as number,
    videoStore: null as any as { [key: string]: any },
    thirdParty: {
      kantar: false, //TODO: window.location.search.includes("kantar=on") || document.cookie.indexOf("kantarInit=true") >= 0 ,
    },
  },
  gtag_id_prod: 'UA-985873-3',
  gtag_id_testing: 'G-QMEX2YL2K2',
}
