import { css } from 'styled-components'
import { Theme } from '../contexts'

export const hostClass = {
  browser: 'browser',
  nativeApp: 'nativeApp',
} as const

export const host = {
  browser: `.${hostClass.browser}`,
  nativeApp: `.${hostClass.nativeApp}`,
} as const

export const size = {
  extraSmall: 600,
  small: 768,
  regular: 1024,
  medium: 1280,
  large: 1300,
} as const

export const device = {
  extraSmall: `@media (min-width: ${size.extraSmall}px)`,
  small: `@media (min-width: ${size.small}px)`,
  regular: `@media (min-width: ${size.regular}px)`,
  medium: `@media (min-width: ${size.medium}px)`,
  large: `@media (min-width: ${size.large}px)`,
} as const

export const maxWidth = {
  medium: '1260px',
  large: '1290px',
  live: '1120px',
} as const

export const maxWidthFl = {
  medium: '1260px',
  large: '1280px',
  live: '1280px',
} as const

export const postHeight = '271px'
export const WarOffset = (haseWarComp: boolean, usePadding?: boolean) => css`
  @media screen and (min-width: 1919px) and (max-width: 1970px) {
    ${haseWarComp
      ? css`
          position: relative;
          ${usePadding
            ? css`
                padding-left: ${(290 - 160) / 2}px;
              `
            : css`
                left: ${(290 - 160) / 2}px;
              `};
        `
      : ''}
  }
`

export const postThumbnailHeight = {
  mobilesmall: '81px',
  mobileMatrix: '95px',
  mobile: '106px',
  medium: '170px',
  midmedium: '186px',
  vertical: '190px',
} as const

export const theme: { [key: string]: Theme } = {
  portal: 'portal',
  vod: 'vod',
  vodMain: 'vod-main',
} as const

export const themeClassName = {
  portal: '.portal',
  vod: '.vod',
  vodMain: '.vod-main',
} as const

export const colors = {
  black: '#000000',
  blueBlack: '#343434',
  whiteGray: '#828282',
  darkGray: '#565656',
  commentsGray: '#848484',
  mediumGray: '#313131',
  tvGuideDark: '#121212',
  alabaster: '#f7f7f7',
  grey: '#555555',
  lightGrey: '#f6f6f6',
  darkHeaderBg: '#232323',
  transparentGray: '#0000004c',
  solidGray: '#e5e5e5',
  backgroundGray: '#f3f3f3',
  separatorGray: '#D8D8D8',
  darkGray2: '#5B5B5B',
  menuOptionGray: '#E6E6E6',
  lightGray2: '#2f2f2f',
  desktopMenuOptionGray: '#ECECEC33',
  desktopMenuOptionGrayLighter: '#ECECECA8',
  lightGray: '#f9f9f9',
  veryLightGray: '#f0f0f0',
  breadCrumbGray: '#969696',
  pink: '#FF1380',
  yellow: '#FFD300',
  white: '#ffffff',
  red: '#f61539',
  liveRed: '#FF0000',
  alertsRed: '#A70601',
  redOpinion: '#b80000',
  blueOpinion: '#00104a',
  darkBlueGray: '#4D5164',
  whiteRed: '#f6173a99',
  blue: '#0038b8',
  darkBlue: '#00104A',
  midnightBlue: '#000721',
  linkBlue: '#00f',
  headerBlue: '#011D6B',
  transparentDarkBlue: '#00104a19',
  termHeadBlockGray: '#2B353E',
  termHeadBlockBG: '#F7F7F9',
  inactiveArrowGray: '#E1E1E1',
  green: '#00F1C8',
  lightGrayBg: 'rgba(111, 111, 111, 0.2)',
  mediumGrayBg: 'rgba(111, 111, 111, 0.4)',
  transparentWhite: 'rgba(255, 255, 255, 0.3)',
  whitish: '#989898',
  gray: '#939393',
  lightBlue: '#00a8f5',
  steelBlue: '#9592A5',
  collapsedButton: '#656565',

  darkOverlay: 'rgba(0, 0, 0, 0.7)',
  seasonSelector: '#0f0f0f',
  adminBG: '#1d2327',
  anotherGrey: '#333',
  anotherLightBlue: '#23527c',
  footerBlue: '#052172',
  newsRed: '#b80000',
  ottBlue: '#0c0f1f',
} as const

export const fonts = {
  default: "'Narkiss Block RESHET', sans-serif",
  narkis: "'Narkiss Block RESHET', sans-serif",
  reshet: 'reshet13',
  aduma: 'Aduma',
  anton: 'Anton',
  leon: 'Leon',
  leonProduct: 'Leon Product',
  robotoCondensed: "'Roboto Condensed', sans-serif",
  weights: {
    Heavy: 900,
    Bold: 700,
    SemiBold: 600,
    Medium: 500,
    Regular: 400,
    Light: 300,
  },
} as const

export const layout = {
  gapMedium: '20px',
  gapLarge: '30px',
  gridBlockGap: '28px',
} as const

export const hideOnMobile = css`
  display: none;

  ${device.medium} {
    display: inherit;
  }
`
export const hideOnDesktop = css`
  display: inherit;

  ${device.medium} {
    display: none;
  }
`

export const layoutItem = css`
  ${device.medium} {
    gap: ${layout.gapMedium};
  }

  ${device.large} {
    gap: ${layout.gapLarge};
  }
`

export const pageMaxWidth = css`
  ${device.medium} {
    margin-left: auto;
    margin-right: auto;
    max-width: ${maxWidth.medium};
  }

  ${device.large} {
    max-width: ${maxWidth.large};
  }
`

export const leftArrow = `&::before {
        content: "";
        display: block;
        width: 3.43px;
        height: 3.43px;
        margin: 1px;
        border-bottom: 2px solid ${colors.darkBlue};
        border-left: 2px solid ${colors.darkBlue};
        transform: rotate(45deg);
    }`

export const hideScrollbar = css`
  ::-webkit-scrollbar {
    display: none; // Chrome, Safari, Edge
  }

  scrollbar-width: none; // Firefox
`

export const playButtonStyle = (isDarkTheme?: boolean) => css`
  > svg {
    position: absolute;
    font-size: 30px;
    left: 12px;
    bottom: -14px;
    border-radius: 100%;
    box-shadow: 0px -4px 7px rgba(0, 0, 0, 0.1);
    z-index: 1;

    ${device.medium} {
      font-size: 50px;
      bottom: -${isDarkTheme ? 3 : 23}px;
    }
  }
`

export const playButtonMainStyle = css`
  > svg {
    position: absolute;
    font-size: 50px;
    left: 16px;
    bottom: -25px;
    border-radius: 100%;
    box-shadow: 0 -4px 7px rgba(0, 0, 0, 0.1);
    z-index: 1;

    ${device.medium} {
      font-size: 40px;
      bottom: -25px;
    }
  }
`

export const playButtonFLStyle = css`
  > svg {
    position: absolute;
    font-size: 24px;
    right: 4px;
    bottom: 4px;
    border-radius: 100%;
    z-index: 9999;
    backdrop-filter: blur(36px);

    ${device.medium} {
      font-size: 40px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
  }
`

export const headerZIndex = 4000
export const accessibilityZIndex = 6000002
export const headerGlobalStyle = {
  mediumMinHeight: '64px',
  minHeight: '48px',
  buttonsWidth: '270px',
  logoWidth: '196px',
}
const cloudi = 'https://media3.reshet.tv/image/upload/'
export const assets = {
  images: {
    logoMobile: `${cloudi}c_scale,h_25,f_auto,q_auto:best/v1684835752/assets/2023/Logo_Together_Mobile.png`,
    logoDesktop: `${cloudi}c_scale,h_35,f_auto,q_auto:best/v1684835752/assets/2023/Logo_Together_Desktop.png`,
  },
}
export const imageSrcBlockAds =
  'https://media3.reshet.tv/image/upload/c_scale,q_auto:best,w_1290/v1669283043/assets/2023/Adblock.webp'
export const zIndex = {
  footerStrip: 2000,
  interstitial: 9000,
  accessibility: 6000003,
  loader: 6000004,
  debugger: 2147483648,
}
