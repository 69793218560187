import {
  IAdvertisers,
  IAllShows,
  IArticlePage,
  IArticlesGrid,
  IBanner,
  IBase,
  IBaseFl,
  ICategoryOpener,
  ICategoryOpenerExtended,
  IComponentTitle,
  IContestants,
  IEpisodesGridFl,
  IEpisodesRowFL,
  IFooter,
  IFrameBlock,
  IGridVideo,
  IGridVodItem,
  IGridVodPage,
  IHorizontalNewsFlashItem,
  IImageCounter,
  ILayout3,
  ILayout4,
  ILayout6,
  ILayout7,
  ILiveBroadcast,
  ILiveVodPage,
  ILiveVodPageGrid,
  ILogoTitle,
  IMainStandard,
  IMainTextOver,
  IMatrix1x4,
  IMatrix2x4,
  IMidBoxMobile,
  INewsFlashItem,
  INewsFlashPage,
  INoTitle,
  IOpeningBannerFl,
  IOpinionsAndPosts,
  IPage,
  IPagination,
  IPaginationFl,
  IPodcastFL,
  IPublishers,
  iRecipePage,
  IReelsFl,
  ISeasonFL,
  ISeparator,
  ISpecialRef,
  ISpecialRefFl,
  ITermHeadBlock,
  ITermHeadBlockFl,
  ITextTitle,
  ITimelineBlock,
  ITimelineBlockFl,
  ITVGuideItem,
  ITVGuidePage,
  IVerticalBlock,
  IVod,
  IVodEpisodes,
  IVodGenreBlock,
  IVodItem,
  IVodLeft,
  IVodMain,
  IVodPage,
  IVodPlaylist,
  IVodPrograms,
  PageGridItem,
} from '.'

export const isNoTitle = (title: IComponentTitle): title is INoTitle => {
  return title.titleType === 'no title'
}

export const isTextTitle = (title: IComponentTitle): title is ITextTitle => {
  return title.titleType === 'text'
}

export const isLogoTitle = (title: IComponentTitle): title is ILogoTitle => {
  return title.titleType === 'logo'
}

export const isSpecialRef = (item: PageGridItem): item is ISpecialRef => {
  const x = item as ISpecialRef
  return x.grid_type === 'SpecialRef'
}

export const isSpecialRefFl = (item: PageGridItem): item is ISpecialRefFl => {
  const x = item as ISpecialRefFl
  return x.grid_type === 'SpecialRef_fl'
}

export const isOpeningBannerFl = (
  item: PageGridItem
): item is IOpeningBannerFl => {
  const x = item as IOpeningBannerFl
  return x.grid_type === 'OpeningBanner_fl'
}

export const isMainStandardItem = (
  item: PageGridItem
): item is IMainStandard => {
  const x = item as IMainStandard
  return x.grid_type === 'MainStandard'
}

export const isLiveBroadcast = (item: PageGridItem): item is ILiveBroadcast => {
  const x = item as ILiveBroadcast
  return x.grid_type === 'LiveBroadcast'
}

export const isMainTextOverItem = (
  item: PageGridItem
): item is IMainTextOver => {
  const x = item as IMainTextOver
  return x.grid_type === 'MainTextOver'
}

export const isIFrameBlock = (item: PageGridItem): item is IFrameBlock => {
  const x = item as IFrameBlock
  return x.grid_type === 'IframeBlock'
}

export const isContestantsComponent = (
  item: PageGridItem
): item is IContestants => {
  const x = item as IContestants
  return x.grid_type === 'contestants'
}
export const isContestantsFLComponent = (
  item: PageGridItem
): item is IContestants => {
  const x = item as IContestants
  return x.grid_type === 'contestants_fl'
}
export const isSeasonFLComponent = (item: PageGridItem): item is ISeasonFL => {
  const x = item as ISeasonFL
  return x.grid_type === 'seasons_fl'
}

export const isEpisodesRowFLComponent = (
  item: PageGridItem
): item is IEpisodesRowFL => {
  const x = item as IEpisodesRowFL
  return x.grid_type === 'episodes_row_fl'
}

export const isPodcastFLComponent = (
  item: PageGridItem
): item is IPodcastFL => {
  const x = item as IPodcastFL
  return x.grid_type === 'podcast_fl'
}

export const isLayout4 = (item: PageGridItem): item is ILayout4 => {
  const x = item as ILayout4
  return x.grid_type === 'standard_four'
}

export const isArticleGrid_fl = (item: PageGridItem): item is IArticlesGrid => {
  const x = item as IArticlesGrid
  return x.grid_type === 'articles_grid_fl'
}

//Episodes for FaceLift project
export const isEpisodesGridFl = (
  item: PageGridItem
): item is IEpisodesGridFl => {
  const x = item as IEpisodesGridFl
  return x.grid_type === 'fiveItems_fl'
}

//Reels for FaceLift project
export const isReelsFl = (item: PageGridItem): item is IReelsFl => {
  const x = item as IReelsFl
  return x.grid_type === 'reels_fl'
}

export const isMatrix1x4 = (item: PageGridItem): item is IMatrix1x4 => {
  const x = item as IMatrix1x4
  return x.grid_type === 'OneOnFourMatrixBlock'
}

export const isMatrix2x4 = (item: PageGridItem): item is IMatrix2x4 => {
  const x = item as IMatrix2x4
  return x.grid_type === 'TwoOnFourMatrixBlock'
}

export const isLayout3 = (item: PageGridItem): item is ILayout3 => {
  const x = item as ILayout3
  return x.grid_type === 'trio_with_banner_or_iframe'
}

export const isLayout6 = (item: PageGridItem): item is ILayout6 => {
  const x = item as ILayout6
  return x.grid_type === 'six_with_banners_or_poster'
}

export const isLayout7 = (item: PageGridItem): item is ILayout7 => {
  const x = item as ILayout7
  return x.grid_type === 'seven_with_banner_or_iframe'
}

export const isPublishersComponent = (
  item: PageGridItem
): item is IPublishers => {
  const x = item as IPublishers
  return x.grid_type === 'advertisers'
}

export const isArticlePage = (page: IPage): page is IArticlePage => {
  const x = page as IArticlePage
  return x.PageMeta.pageType === 'item' && !x.PageMeta.isFaceLift
}
export const isArticlePageFl = (page: IPage): page is IArticlePage => {
  const x = page as IArticlePage
  return x.PageMeta.pageType === 'item' && x.PageMeta.isFaceLift
}

export const isRecipePage = (page: IPage): page is iRecipePage => {
  const x = page as IArticlePage
  return x.Content.Item.itemType === 'recipe'
}

export const isArticleTimeLinePage = (page: IPage): page is IArticlePage => {
  const x = page as IArticlePage
  const timeline = x.Content.Item.timeLines
  return (
    timeline && Object.keys(timeline).length !== 0 && !page.PageMeta.isFaceLift
  )
}

export const isArticleTimeLinePageFL = (page: IPage): page is IArticlePage => {
  const x = page as IArticlePage
  const timeline = x.Content.Item.timeLines
  return (
    timeline && Object.keys(timeline).length !== 0 && page.PageMeta.isFaceLift
  )
}

export const isAuthorCategoryTagPage = (page: IPage): page is IPage => {
  const x = page as IPage
  return (
    (x.PageMeta.pageType === 'category' ||
      x.PageMeta.pageType === 'tag' ||
      x.PageMeta.pageType === 'writer' ||
      x.PageMeta.pageType === 'grid' ||
      x.PageMeta.pageType === 'hp') &&
    !x.PageMeta.isFaceLift
  )
}

//AuthorCategoryTagPage for FaceLift project
export const isAuthorCategoryTagPageFl = (page: IPage): page is IPage => {
  const x = page as IPage
  return (
    (x.PageMeta.pageType === 'category' ||
      x.PageMeta.pageType === 'tag' ||
      x.PageMeta.pageType === 'writer' ||
      x.PageMeta.pageType === 'grid' ||
      x.PageMeta.pageType === 'hp') &&
    x.PageMeta.isFaceLift
  )
}

export const isHomepage = (page: IPage): page is IPage => {
  const x = page as IPage
  return x.PageMeta.pageType === 'hp'
}

export const isTagPage = (page: IPage): page is IPage => {
  const x = page as IPage
  return x.PageMeta.pageType === 'tag'
}

export const isWriterPage = (page: IPage): page is IPage => {
  const x = page as IPage
  return x.PageMeta.pageType === 'writer'
}

export const isNewsFlashesPage = (page: IPage): page is INewsFlashPage => {
  const x = page as INewsFlashPage
  return x.PageMeta.pageType === 'mivzakim'
}

export const isTVGuidePage = (page: IPage): page is ITVGuidePage => {
  const x = page as ITVGuidePage
  return x.PageMeta.pageType === 'tvguide'
}

export const isVodPage = (page: IPage): page is IVodPage => {
  const x = page as IVodPage
  return (
    x.PageMeta.pageType === 'hpvod' ||
    x.PageMeta.pageType === 'allprogramsvod' ||
    x.PageMeta.pageType === 'genresvod'
  )
}

export const isGridVodPage = (page: IPage): page is IGridVodPage => {
  const x = page as IGridVodPage
  return x.PageMeta?.pageType === 'gridvod'
}

//Pager for FaceLift project
export const isPager = (item: PageGridItem): item is IPagination => {
  const x = item as IPagination
  return x.grid_type === 'PaginationArray'
}
export const isPagerFl = (item: PageGridItem): item is IPaginationFl => {
  const x = item as IPaginationFl
  return x.grid_type === 'PaginationArray_fl'
}

export const isBase = (item: PageGridItem): item is IBase => {
  const x = item as IBase
  return x.grid_type === 'Base'
}
//Base for FaceLift project
export const isBaseFl = (item: PageGridItem): item is IBaseFl => {
  const x = item as IBaseFl
  return x.grid_type === 'Base_fl'
}

export const isTermHeadBlock = (item: PageGridItem): item is ITermHeadBlock => {
  const x = item as ITermHeadBlock
  return x.grid_type === 'TermHeadBlock'
}
//TermHeadBlock for FaceLift project
export const isTermHeadBlockFl = (
  item: PageGridItem
): item is ITermHeadBlockFl => {
  const x = item as ITermHeadBlockFl
  return x.grid_type === 'TermHeadBlock_fl'
}

export const IsVideoGridComponent = (
  item: PageGridItem
): item is IGridVideo => {
  const x = item as IGridVideo
  return x.grid_type === 'VideoGridComponent'
}
export const IsImageCounterComponent = (
  item: PageGridItem
): item is IImageCounter => {
  const x = item as IImageCounter
  return x.grid_type === 'CounterItemBlock'
}

export const isCategoryOpener = (
  item: PageGridItem
): item is ICategoryOpener => {
  const x = item as ICategoryOpener
  return x.grid_type === 'CategoryOpener'
}

export const isCategoryOpenerExtended = (
  item: PageGridItem
): item is ICategoryOpenerExtended => {
  const x = item as ICategoryOpenerExtended
  return x.grid_type === 'CategoryOpenerExtended'
}

export const isVod = (item: PageGridItem): item is IVod => {
  const x = item as IVod
  return x.grid_type === 'VodPromotional'
}

export const isLiveVodPage = (page: IPage): page is ILiveVodPage => {
  const x = page as ILiveVodPage
  return x.PageMeta.pageType === 'live'
}

export const isVodEpisodes = (item: IVodItem): item is IVodEpisodes => {
  const x = item as IVodEpisodes
  return x.grid_type === 'VodEpisodesCarouselBlock'
}

export const isVodPrograms = (item: IVodItem): item is IVodPrograms => {
  const x = item as IVodPrograms
  return x.grid_type === 'VodPrograms'
}

export const isVodMain = (item: IVodItem): item is IVodMain => {
  const x = item as IVodMain
  return x.grid_type === 'VodMain'
}

export const isAllShows = (item: IVodItem): item is IAllShows => {
  const x = item as IAllShows
  return x.grid_type === 'AllShows'
}

export const isVodGenresBlock = (item: IVodItem): item is IVodGenreBlock => {
  const x = item as IVodGenreBlock
  return x.grid_type === 'VodGenresBlock'
}

export const isSeparator = (item: PageGridItem): item is ISeparator => {
  const x = item as ISeparator
  return x.grid_type === 'Separator'
}

export const isHorizontalNewsFlashes = (
  item: PageGridItem
): item is IHorizontalNewsFlashItem => {
  const x = item as IHorizontalNewsFlashItem
  return x.grid_type === 'NewsFlashBlock'
}

export const isVodLeft = (item: IGridVodItem): item is IVodLeft => {
  const x = item as IVodLeft
  return x.grid_type === 'VodLeft'
}

export const isVodPlaylist = (item: IGridVodItem): item is IVodPlaylist => {
  const x = item as IVodPlaylist
  return x.grid_type === 'VodPlaylist'
}

export const isTimeline = (
  data:
    | ITimelineBlock
    | ITimelineBlockFl
    | IFooter
    | INewsFlashItem
    | ITVGuideItem
    | ILiveVodPageGrid
    | IVodMain
    | IVodEpisodes
    | IVodPrograms
    | IAllShows
    | IVodGenreBlock
    | IVodLeft
    | IVodPlaylist
    | IArticlesGrid
    | IAdvertisers
    | ITermHeadBlockFl
    | IBaseFl
    | IPaginationFl
    | IMidBoxMobile
): data is ITimelineBlock => {
  const x = data as ITimelineBlock
  return x.grid_type === 'Timeline'
}

export const isTimelineFl = (
  data:
    | ITimelineBlockFl
    | IFooter
    | INewsFlashItem
    | ITVGuideItem
    | ILiveVodPageGrid
    | IVodMain
    | IVodEpisodes
    | IVodPrograms
    | IAllShows
    | IVodGenreBlock
    | IVodLeft
    | IVodPlaylist
    | IArticlesGrid
    | IAdvertisers
    | ITermHeadBlockFl
    | IBaseFl
    | IPaginationFl
    | IMidBoxMobile
): data is ITimelineBlockFl => {
  const x = data as ITimelineBlockFl
  return x.grid_type === 'Timeline_fl'
}

export const isLiveBlock = (data: ILiveVodPageGrid) => {
  return data.grid_type === 'LiveBlock'
}
export const isOpinion = (item: PageGridItem): item is IOpinionsAndPosts => {
  const x = item as IOpinionsAndPosts
  return x.grid_type === 'six_with_opinions'
}
export const isAdvertisers = (item: PageGridItem): item is IAdvertisers => {
  const x = item as IAdvertisers
  return x.grid_type === 'advertisers_fl'
}
export const isMidBoxMobile = (item: PageGridItem): item is IMidBoxMobile => {
  if (item.grid_type) {
    return ['mid_box_mobile_block', 'mid_box_block'].includes(item.grid_type)
  }
  return false
}

export const isVerticalBlock = (item: PageGridItem): item is IVerticalBlock => {
  const x = item as IVerticalBlock
  return x.grid_type === 'VerticalPlayerComponent'
}
export const isBanner = (item: PageGridItem): item is IBanner => {
  const x = item as IBanner
  return x.grid_type === 'Banner'
}
